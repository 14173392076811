import { useRouter } from 'next/router'
import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import IconClubCard from '@components/atoms/Icons/IconClubCard'
import IconCoupons from '@components/atoms/Icons/IconCoupons'
import LogoWellmaxx from '@components/atoms/Logos/LogoWellmaxx'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { RoutesWellmaxxDefault } from '@definitions/routes/Routes'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import {
  Buttons,
  Logo,
  Root,
  StyledBurger,
  StyledButton,
  StyledHeaderNavigationSunpoint,
  StyledLink,
  StyledSpan,
} from './HeaderWellmaxx.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
}

const HeaderWellmaxx: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { menuData } = props
  const [open, setOpen] = useState<boolean>(false)
  const router = useRouter()
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })

  const handleClick = (): void => {
    setOpen(!open)
  }
  const closeMenu = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleScroll = (open: boolean) => {
      if (open && isMobile) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
    const scrollHandler = () => handleScroll(open)

    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [open, isMobile])
  return (
    <Root
      open={open}
      className={props.className}
      data-testid={'header-wellmaxx-root'}
    >
      <Logo>
        <StyledLink aria-label="Home Link" href={RoutesWellmaxxDefault.INDEX}>
          <LogoWellmaxx height={32} />
        </StyledLink>
      </Logo>
      <StyledHeaderNavigationSunpoint
        closeMenu={() => closeMenu()}
        open={open}
        menuData={menuData}
      />
      <Buttons open={open}>
        <StyledButton
          modifier={ButtonModifier.PRIMARY}
          disabled={false}
          buttonType={ButtonTypes.BUTTON}
          icon={<IconClubCard />}
          iconAlign={IconAligns.LEFT}
          loading={false}
          onClick={() =>
            router.push(RoutesWellmaxx.WELLMAXX__CLUBMITGLIEDWERDEN)
          }
        >
          <span>Clubmitglied</span>
          <StyledSpan>&nbsp;werden</StyledSpan>
        </StyledButton>
        <StyledButton
          modifier={ButtonModifier.SECONDARY}
          disabled={false}
          buttonType={ButtonTypes.BUTTON}
          icon={<IconCoupons />}
          loading={false}
          iconAlign={IconAligns.LEFT}
          onClick={() => router.push(RoutesWellmaxx.WELLMAXX__COUPONS)}
        >
          Coupons
        </StyledButton>
      </Buttons>
      <StyledBurger onClick={handleClick} open={open} />
    </Root>
  )
}

export { HeaderWellmaxx }
