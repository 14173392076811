import { StoryblokPage } from 'pages/sunpoint'
import React, { PropsWithChildren } from 'react'

import FooterWellmaxx from '@components/organisms/FooterWellmaxx'
import HeaderWellmaxx from '@components/organisms/HeaderWellmaxx'

import { useUserData } from '@helper/context/UserDataContext'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Main, Root } from './WellmaxxTemplateDefault.styles'

interface Props extends PropsWithClassName {
  menuData: StoryblokPage[]
}

const WellmaxxTemplateDefault: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, menuData } = props
  return (
    <Root
      className={props.className}
      data-testid={'wellmaxx-template-default-root'}
    >
      <HeaderWellmaxx menuData={menuData} />
      <Main>{children}</Main>
      <FooterWellmaxx />
    </Root>
  )
}

export { WellmaxxTemplateDefault }
