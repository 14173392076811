import Link from 'next/link'
import styled from 'styled-components'

import Burger from '@components/atoms/Burger'
import Button from '@components/atoms/Button'
import { Span } from '@components/atoms/Button/Button.styles'
import LogoSunpoint from '@components/atoms/Logos/LogoSunpoint'
import HeaderNavigationSunpoint from '@components/molecules/HeaderNavigationSunpoint'

interface HeaderProps {
  open: boolean
}

export const Root = styled.header<HeaderProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${(props) => (props.open ? 'flex-start' : 'flex-start')};
  box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 130px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  transition: all 200ms ease-in-out;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    padding: 1rem 1.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin: auto;
    height: 100px;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
  }
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    justify-content: space-between;
    padding: 0 1.5rem;
    margin: auto;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: ${(props) => props.theme.palette.default.white};
    display: ${(props) => (props.open ? 'flex' : 'none')};
    @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
      display: none;
    }
  }
`

export const Logo = styled.div`
  display: flex;
  order: 2;
  position: absolute;
  bottom: 20px;
  left: 1rem;
  z-index: 20;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    order: 1;
    position: relative;
    bottom: auto;
    left: auto;
  }
`

export const StyledLink = styled(Link)`
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    width: auto;
    padding: 0 1rem;
  }
`

export const StyledLogoSunpoint = styled(LogoSunpoint)`
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
  }
`

export const StyledHeaderNavigationSunpoint = styled(HeaderNavigationSunpoint)`
  order: 4;
  position: absolute;
  top: 130px;
  left: 0;
  z-index: 20;
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    order: 2;
    position: relative;
    top: auto;
    left: auto;
  }
`

export const StyledButton = styled(Button)`
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  ${Span} {
    display: inline-flex;
  }
`
export const StyledSpan = styled.span`
  display: none;
  @media screen and (min-width: 370px) {
    display: flex;
  }
`

export const Buttons = styled.div<HeaderProps>`
  order: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  display: flex;
  z-index: 20;
  width: 100%;
  background: ${(props) => props.theme.palette.default.greyLight};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    background: none;
    padding: 1.5rem 0;
    order: 3;
    width: auto;
    display: flex;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
  @media screen and (max-width: ${(props) => props.theme.device.mobile}) {
    ${StyledButton} {
      border: none;
      background: none;
      color: ${(props) => props.theme.palette.default.primary};
      svg {
        path {
          fill: ${(props) => props.theme.palette.default.primary};
        }
        g {
          stroke: ${(props) => props.theme.palette.default.primary};
        }
        circle {
          stroke: ${(props) => props.theme.palette.default.primary};
        }
      }
      &:hover,
      &:active {
        svg {
          path {
            fill: ${(props) => props.theme.palette.default.primary};
          }
          g {
            stroke: ${(props) => props.theme.palette.default.primary};
          }
          circle {
            stroke: ${(props) => props.theme.palette.default.primary};
          }
        }
      }
    }
  }
`
export const StyledBurger = styled(Burger)`
  order: 4;
  position: absolute;
  bottom: 25px;
  right: 1rem;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    order: 4;
    position: relative;
    bottom: auto;
    right: auto;
  }
`
