import Link from 'next/link'
import React, { PropsWithChildren, useCallback } from 'react'

import { ButtonModifier } from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import IconFacebook from '@components/atoms/Icons/IconFacebook'
import IconInstagram from '@components/atoms/Icons/IconInstagram'
import IconMail from '@components/atoms/Icons/IconMail'
import IconPhone from '@components/atoms/Icons/IconPhone'
import IconYoutube from '@components/atoms/Icons/IconYoutube'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import UnorderedList from '@components/atoms/UnorderedList'
import Newsletter from '@components/molecules/Newsletter'
import {
  Claim,
  Copyright,
  FirstSection,
  LastText,
  MetaLinkCol,
  Root,
  SameHeightCol,
  Section,
  Social,
  SocialButton,
  StyledBox,
  StyledHorizontalRule,
  StyledIconClubCard,
  StyledIconHeadset,
  StyledLink,
  StyledListItem,
  StyledListLink,
  StyledLogoWellmaxx,
  StyledMetaLink,
} from '@components/organisms/FooterSunpoint/FooterSunpoint.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { themeWellmaxx } from '@helper/theme'

import { RoutesWellmaxxDefault } from '@definitions/routes/Routes'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

interface Props extends PropsWithClassName {}

const FooterWellmaxx: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const cookieSettingsOnclick = useCallback((e: any) => {
    if (typeof window !== 'undefined' && 'UC_UI' in window) {
      e.preventDefault()
      const ucUi: any = window['UC_UI']
      ucUi.showSecondLayer()
    }
  }, [])

  return (
    <Root className={props.className} data-testid={'footer-wellmaxx-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Link aria-label="Home Link" href={RoutesWellmaxxDefault.INDEX}>
            <StyledLogoWellmaxx height={35} />
          </Link>
        </Col>
      </Row>
      <FirstSection>
        <Row>
          <SameHeightCol xs={12} sm={12} md={6} lg={4}>
            <StyledBox>
              <StyledIconClubCard />
              <Headline importance={HeadlineImportances.h5}>
                Club Service
              </Headline>
              <Text variant={TextVariants.meta}>
                Für Fragen zu deinem Clubvertrag. Unsere Club FAQ-Seite kann
                schnell weiterhelfen!
              </Text>
              <UnorderedList>
                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'mailto:club-service@wellmaxx.com'}
                      aria-label="Club Service Mail"
                    >
                      <IconMail color={themeWellmaxx.palette.default.primary} />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'mailto:club-service@wellmaxx.com'}
                    aria-label="club-service@wellmaxx.com"
                  >
                    club-service@wellmaxx.com
                  </StyledListLink>
                </StyledListItem>
                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'mailto:club-service@wellmaxx.com'}
                      aria-label="Club Service Phone"
                    >
                      <IconPhone
                        color={themeWellmaxx.palette.default.primary}
                      />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'tel:#49223498422655'}
                    aria-label="02234 98422 655"
                  >
                    02234 98422 655
                  </StyledListLink>
                </StyledListItem>
              </UnorderedList>
              <LastText variant={TextVariants.paragraph}>
                Mo. bis Fr. 10 – 16 Uhr
              </LastText>
            </StyledBox>
          </SameHeightCol>
          <SameHeightCol xs={12} sm={12} md={6} lg={4}>
            <StyledBox>
              <StyledIconHeadset />
              <Headline importance={HeadlineImportances.h5}>
                Allgemeiner Service
              </Headline>
              <Text variant={TextVariants.meta}>
                Für Fragen oder Anregungen zum Studio, unseren Produkten und
                Dienstleistungen.
              </Text>
              <UnorderedList>
                <StyledListItem
                  icon={
                    <StyledListLink
                      aria-label="Info Email"
                      href={'mailto:info@wellmaxx.com'}
                    >
                      <IconMail color={themeWellmaxx.palette.default.primary} />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'mailto:info@wellmaxx.com'}
                    aria-label="info@wellmaxx.com"
                  >
                    info@wellmaxx.com
                  </StyledListLink>
                </StyledListItem>

                <StyledListItem
                  icon={
                    <StyledListLink
                      href={'tel:#49223498422333'}
                      aria-label="Info Phone"
                    >
                      <IconPhone
                        color={themeWellmaxx.palette.default.primary}
                      />
                    </StyledListLink>
                  }
                >
                  <StyledListLink
                    href={'tel:#49223498422333'}
                    aria-label="02234 98422 333"
                  >
                    02234 98422 333
                  </StyledListLink>
                </StyledListItem>
              </UnorderedList>
              <LastText variant={TextVariants.paragraph}>
                Mo. bis Fr. 10 – 16 Uhr
              </LastText>
            </StyledBox>
          </SameHeightCol>
          <SameHeightCol xs={12} sm={12} md={12} lg={4}>
            <Newsletter list="wellmaxx" inverted />
          </SameHeightCol>
        </Row>
      </FirstSection>
      <Section>
        <Row>
          <Col xs={12} sm={4} md={3} lg={3}>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__CLUBMITGLIEDWERDEN}>
              Clubmitglied werden
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__NEWSLETTER}>
              Newsletter
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__KOOPERATIONENINFLUENCER}>
              Kooperationen & Influencer
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__KONTAKT}>
              Kontakt
            </StyledLink>
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__HAENDLERANFRAGEN}>
              Händleranfragen
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__PRESSE}>
              Presse
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__CLUBFAQS}>
              FAQs
            </StyledLink>
            <StyledLink href={RoutesWellmaxx.WELLMAXX__CLUBVERTRAGKUENDIGEN}>
              Clubvertrag kündigen
            </StyledLink>
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}></Col>
          <Col xs={12} sm={12} md={3} lg={3}>
            <Claim variant={TextVariants.lead}>Wir machen #Beauty</Claim>
            <Social>
              <SocialButton
                loading={false}
                href={RoutesWellmaxxDefault.FACEBOOK}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Facebook Link'}
              >
                <IconFacebook />
              </SocialButton>
              <SocialButton
                loading={false}
                href={RoutesWellmaxxDefault.INSTAGRAM}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Instagram Link'}
              >
                <IconInstagram />
              </SocialButton>
              <SocialButton
                loading={false}
                href={RoutesWellmaxxDefault.YOUTUBE}
                disabled={false}
                modifier={ButtonModifier.TERTIARY}
                target="_blank"
                label={'Youtube Link'}
              >
                <IconYoutube />
              </SocialButton>
            </Social>
          </Col>
        </Row>
      </Section>
      <Section>
        <StyledHorizontalRule />
      </Section>
      <Section>
        <Row>
          <Col xs={12} sm={12} md={5} lg={4}>
            <Copyright variant={TextVariants.paragraph}>
              &copy; {new Date().getFullYear()} SUNPOINT
            </Copyright>
          </Col>
          <MetaLinkCol xs={12} sm={12} md={7} lg={8}>
            <StyledMetaLink href={RoutesWellmaxx.WELLMAXX__AGB}>
              AGB
            </StyledMetaLink>
            <StyledMetaLink href={RoutesWellmaxx.WELLMAXX__WIDERRUFSBELEHRUNG}>
              Widerrufsbelehrung
            </StyledMetaLink>
            <StyledMetaLink href={RoutesWellmaxx.WELLMAXX__IMPRESSUM}>
              Impressum
            </StyledMetaLink>
            <StyledMetaLink href={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}>
              Datenschutz
            </StyledMetaLink>
            <StyledMetaLink onClick={cookieSettingsOnclick} href={'#'}>
              Cookie-Einstellungen
            </StyledMetaLink>
          </MetaLinkCol>
        </Row>
      </Section>
    </Root>
  )
}

export { FooterWellmaxx }
